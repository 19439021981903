// 内容中心
const categoryLaw = () => import('@/webapp/content/category/law.vue')
const categoryOther = () => import('@/webapp/content/category/other.vue')
const learningRegulation = () => import('@/webapp/content/learning/regulation.vue')
// const learningNous = () => import('@/webapp/content/learning/nous.vue')
const learningTypical = () => import('@/webapp/content/learning/typical.vue')
const learningFaq = () => import('@/webapp/content/learning/faq.vue')
const learningPreview = () => import('@/webapp/content/learning/preview.vue')
const judgeCase = () => import('@/webapp/content/learning/judge-case.vue')
const writTemplate = () => import('@/webapp/content/writ/template.vue')

const lawWrit = () => import('@/webapp/content/writ/law-writ.vue')
const writRisk = () => import('@/webapp/content/writ/risk.vue')
const writClause = () => import('@/webapp/content/writ/clause.vue')
const writSensitive = () => import('@/webapp/content/writ/sensitive.vue')
const writKeyword = () => import('@/webapp/content/writ/keyword.vue')
const unknownFaq = () => import('@/webapp/content/learning/unknown-faq.vue')
const learningNouns = () => import('@/webapp/content/learning/nouns.vue')
const classroomCourse = () => import('@/webapp/content/classroom/course.vue')
const busProblem = () => import('@/webapp/content/learning/bus-problem.vue')
const mpArticle = () => import('@/webapp/content/learning/mp-article.vue')

const Api = require('../apis/path')
export default [
  {
    path: '/content',
    meta: {
      id: '9643aa2e-d9be-4e03-b679-f76b01a827a9',
      icon: '',
      name: 'content',
      title: '内容中心',
      apis: [
      ]
    },
    children: [
      {
        path: '/category',
        meta: {
          id: '1942562e-121f-4f17-b03b-43ffead5120c',
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'category',
          title: '门类管理',
          apis: []
        },
        children: [
          {
            path: '/law',
            meta: {
              id: '847a5a49-6db7-4ad0-887f-3bd7b77bf642',
              icon: '',
              name: 'law',
              title: '法律门类管理',
              apis: [
                `get:${Api.path.knowledge}/category/subs/{parentId}`,
                `get:${Api.path.knowledge}/category/list/{type}`,
                `get:${Api.path.knowledge}/law/page/list`,
                `delete:${Api.path.knowledge}/law/{id}`,
                `post:${Api.path.knowledge}/law`,
                `put:${Api.path.knowledge}/law/{id}`,
                `get:${Api.path.knowledge}/law/page/list`,
                `get:${Api.path.knowledge}/law/issued/list`,
                `get:${Api.path.knowledge}/law/mechanism/all`,
                `get:${Api.path.knowledge}/law/mechanism/list/by/parent/{id}`,
                `post:${Api.path.knowledge}/law/mechanism`,
                `put:${Api.path.knowledge}/law/mechanism/{id}`,
                `delete:${Api.path.knowledge}/law/mechanism/{id}`,
                `get:${Api.path.knowledge}/law/list/master`,
                `put:${Api.path.knowledge}/law/top/{id}`
              ],
              actions: [
                {
                  id: 'b9c3c829-e488-4f1d-9477-e29c097c4e0f',
                  name: 'add',
                  title: '新增门类',
                  apis: [`post:${Api.path.knowledge}/category`]
                },
                {
                  id: 'cdf0ea16-59a8-4c51-b401-b55f23ca8497',
                  name: 'edit',
                  title: '编辑门类',
                  apis: [`put:${Api.path.knowledge}/category/{id}`, `put:${Api.path.knowledge}/category/sort/{id}`]
                },
                {
                  id: '1c9b9698-37ae-484a-bc84-247da831857f',
                  name: 'edit',
                  title: '删除门类',
                  apis: [`delete:${Api.path.knowledge}/category/{id}`]
                }
              ]
            },
            component: categoryLaw
          },
          {
            path: '/other',
            meta: {
              id: '3e02e092-175c-483f-8d19-e946b860d1dd',
              icon: '',
              name: 'other',
              title: '其它门类管理',
              apis: [
                `get:${Api.path.knowledge}/category/subs/{parentId}`,
                `get:${Api.path.knowledge}/category/list/{type}`
              ],
              actions: [
                {
                  id: 'c4a9958b-91f3-4d0b-880b-2b3f9a3f86e1',
                  name: 'add',
                  title: '新增门类',
                  apis: [`post:${Api.path.knowledge}/category`]
                },
                {
                  id: '42ecab2d-88a2-43df-916e-708851b0aca9',
                  name: 'edit',
                  title: '编辑门类',
                  apis: [`put:${Api.path.knowledge}/category/{id}`]
                },
                {
                  id: 'e226c785-4aeb-4dd4-9868-86f315ff8614',
                  name: 'edit',
                  title: '删除门类',
                  apis: [`delete:${Api.path.knowledge}/category/{id}`]
                }
              ]
            },
            component: categoryOther
          }
        ]
      },
      {
        path: '/learning',
        meta: {
          id: '77859d84-913d-47cc-a0ae-73fa69c20bf7',
          icon: 'iconfont icon-pufaxuefaicon',
          iconColor: '#fd9643',
          name: 'learning',
          title: '普法学法管理'
        },
        children: [
          {
            path: '/regulation',
            meta: {
              id: '970b0abf-7eeb-4cf2-84af-a22657ca53db',
              icon: '',
              name: 'regulation',
              title: '法律法规',
              apis: [
                `get:${Api.path.knowledge}/law/rule/law/{lawId}`,
                `put:${Api.path.knowledge}/law/rule/{id}`,
                `post:${Api.path.knowledge}/law/rule`,
                `get:${Api.path.knowledge}/law/{id}`,
                `get:${Api.path.knowledge}/law/rule/page/list`,
                `get:${Api.path.knowledge}/category/subs/{parentId}`,
                `get:${Api.path.knowledge}/category/list/{type}`,
                `get:${Api.path.knowledge}/law/rule/tree/{lawId}`,
                `delete:${Api.path.knowledge}/law/rule/regulations/{id}`,
                `get:${Api.path.knowledge}/law/rule/tree/{lawId}`,
                `put:${Api.path.knowledge}/law/rule/regulations/{id}`,
                `get:${Api.path.knowledge}/law/rule/{id}`,
                `delete:${Api.path.knowledge}/law/rule/{id}`,
                `delete:${Api.path.knowledge}/law/rule/batch`,
                `post:${Api.path.knowledge}/law/batch`,
                `get:${Api.path.knowledge}/law/mechanism/all`
              ],
              actions: [
                {
                  id: '81060b8d-b09b-4e4c-8538-9acaebe8cb42',
                  name: 'add',
                  title: '新增',
                  apis: []
                },
                {
                  id: '5e4c808b-6e39-44c8-b13b-69339e0c9c08',
                  name: 'edit',
                  title: '编辑',
                  apis: []
                },
                {
                  id: 'ec435fb6-ddd8-4bb1-a9c4-ebb3ba396fee',
                  name: 'del',
                  title: '删除',
                  apis: []
                },
                {
                  id: '97113c8b-e1c3-40eb-9126-4f2cb5e785ca',
                  name: 'import',
                  title: '导入',
                  apis: []
                },
                {
                  id: 'ede52726-6beb-4669-9351-31b9a3a709b4',
                  name: 'batch',
                  title: '批量操作',
                  apis: []
                }

              ]
            },
            component: learningRegulation
          },
          {
            path: '/preview',
            component: learningPreview
          },
          {
            path: '/judge-case',
            meta: {
              id: '05cc8a1d-ccfb-4ef5-ba5a-8592dda3f7f2',
              icon: '',
              name: 'judgeCase',
              title: '裁判案例',
              apis: [
                `get:${Api.path.knowledge}/law/case/library/page/list`,
                `get:${Api.path.knowledge}/law/case/library/{id}`,
                `post:${Api.path.knowledge}/law/case/library`,
                `put:${Api.path.knowledge}/law/case/library/{id}`,
                `delete:${Api.path.knowledge}/law/case/library/{id}`,
                `get:${Api.path.knowledge}/law/trial/court/all`
              ],
              actions: [
                { id: 'f4dc6f4c-a191-49a5-9b6f-6ee6ff907d51', name: 'cagetory_add', title: '分类新增' },
                { id: '0d47111f-1e05-4ded-a7bf-b1ccdefd5b12', name: 'cagetory_edit', title: '分类编辑' },
                { id: 'cb4ee529-9b70-4fde-8a8b-7afc48ac5bb5', name: 'cagetory_del', title: '分类删除' },
                { id: '32debb2d-c87a-4c33-acd2-e929625c640a', name: 'add', title: '新增' },
                { id: '8a1ab3b4-98da-4574-9dce-c8e0c93a5099', name: 'edit', title: '编辑' },
                { id: '7157d843-57e2-4d7a-8fd1-cb7c7ada343d', name: 'del', title: '删除' }
              ]
            },
            component: judgeCase
          },
          {
            path: '/typical',
            meta: {
              id: '0aef02cb-88dc-425c-bd96-cdee4b9d804c',
              icon: '',
              name: 'typical',
              title: '经典案例',
              apis: [
                `get:${Api.path.knowledge}/law/case/{id}`,
                `put:${Api.path.knowledge}/law/case/{id}`,
                `post:${Api.path.knowledge}/law/case`,
                `get:${Api.path.knowledge}/category/all`,
                `get:${Api.path.knowledge}/category/subs/{parentId}`,
                `get:${Api.path.knowledge}/category/list/{type}`,
                `get:${Api.path.knowledge}/law/case/page/list`,
                `delete:${Api.path.knowledge}/law/case/{id}`,
                `delete:${Api.path.knowledge}/law/case/batch`,
                `put:${Api.path.knowledge}/law/case/batch`
              ],
              actions: [
                {
                  id: '6d7c72da-82a3-4a1a-87b8-9ee034a9c5cd',
                  name: 'add',
                  title: '新增',
                  apis: []
                },
                {
                  id: 'd32f205a-e613-48f3-82cf-3f5366c3698a',
                  name: 'edit',
                  title: '编辑',
                  apis: []
                },
                {
                  id: '9e2639a9-3cc4-4873-a35f-0c07af79a7e3',
                  name: 'del',
                  title: '删除',
                  apis: []
                },
                {
                  id: '658e00fa-7f04-4e9b-a846-ee00e3cdbfef',
                  name: 'batch',
                  title: '批量操作',
                  apis: []
                }
              ]
            },
            component: learningTypical
          },
          {
            path: '/case',
            meta: {
              id: '741e3147-ee7d-4ddd-9037-ec1c63bcfcde',
              icon: '',
              name: 'case',
              title: '常见问题',
              apis: [
                `get:${Api.path.knowledge}/category/all`,
                `get:${Api.path.knowledge}/category/subs/{parentId}`,
                `get:${Api.path.knowledge}/category/list/{type}`,
                `get:${Api.path.knowledge}/law/question/page/list`,
                `get:${Api.path.knowledge}/law/question/{id}`,
                `put:${Api.path.knowledge}/law/question/{id}`,
                `delete:${Api.path.knowledge}/law/question/{id}`,
                `post:${Api.path.knowledge}/law/question`,
                `delete:${Api.path.knowledge}/law/question/{id}`,
                `delete:${Api.path.knowledge}/law/question/batch`,
                `put:${Api.path.knowledge}/law/question/batch`,
                `post:${Api.path.knowledge}/law/question/batch`,
                `post:${Api.path.knowledge}/law/question/export/{type}`
              ],
              actions: [
                {
                  id: '41b9f61a-6f67-446b-a056-9e9952183991',
                  name: 'add',
                  title: '新增',
                  apis: []
                },
                {
                  id: 'cfbaf606-60e7-4ea7-9c6f-7a92d951df9a',
                  name: 'edit',
                  title: '编辑',
                  apis: []
                },
                {
                  id: '248cd71b-da68-41a3-bd98-c97362db288c',
                  name: 'del',
                  title: '删除',
                  apis: []
                },
                {
                  id: 'a5c78172-7f09-41f8-a431-52e9b5d148b7',
                  name: 'import',
                  title: '导入',
                  apis: []
                },
                {
                  id: '5f3b6480-3274-4992-a2ce-dee942fc2aec',
                  name: 'export',
                  title: '导出',
                  apis: []
                },
                {
                  id: 'ede52726-6beb-4669-9351-31b9a3a709b4',
                  name: 'batch',
                  title: '批量操作',
                  apis: []
                }

              ]
            },
            component: learningFaq
          },
          {
            path: '/unknown-faq',
            meta: {
              id: '8d0aef3b-d3ac-4585-a351-f75c51e9e70e',
              icon: '',
              name: 'unknown-faq',
              title: '未知问题',
              apis: [
                `get:${Api.path.knowledge}/question/unknow/page/list`,
                `get:${Api.path.knowledge}/category/all`,
                `get:${Api.path.knowledge}/category/subs/{parentId}`,
                `get:${Api.path.knowledge}/category/list/{type}`,
                `get:${Api.path.knowledge}/law/question/unknow/page/list`,
                `get:${Api.path.knowledge}/law/question/page/list`,
                `put:${Api.path.knowledge}/law/question/unknow/status`,
                `post:${Api.path.knowledge}/law/question/unknow/insert`,
                `put:${Api.path.knowledge}/law/question/unknow/study`
              ],
              actions: []
            },
            component: unknownFaq
          },
          {
            path: '/nouns',
            meta: {
              id: '3d16c7c0-b508-4f04-b6a0-bf71b72f673b',
              icon: '',
              name: 'nouns',
              title: '法律名词',
              apis: [
                `get:${Api.path.knowledge}/law/noun/page/list`,
                `post:${Api.path.knowledge}/law/noun`,
                `get:${Api.path.knowledge}/law/noun/{id}`,
                `put:${Api.path.knowledge}/law/noun/{id}`,
                `delete:${Api.path.knowledge}/law/noun/{id}`,
                `post:${Api.path.knowledge}/law/noun/batch`
              ],
              actions: []
            },
            component: learningNouns
          },
          {
            path: '/elucidation',
            meta: {
              id: 'b063f241-3310-4bc5-b072-f15a56ab1967',
              icon: '',
              name: 'elucidation',
              title: '政策解读',
              apis: [
                `get:${Api.path.knowledge}/law/policy/interpretation/page/list`,
                `get:${Api.path.knowledge}/law/policy/interpretation/{id}`,
                `post:${Api.path.knowledge}/law/policy/interpretation`,
                `put:${Api.path.knowledge}/law/policy/interpretation/{id}`,
                `post:${Api.path.knowledge}/law/policy/interpretation/{id}`,
                `delete:${Api.path.knowledge}/law/policy/interpretation/batch`,
                `delete:${Api.path.knowledge}/law/policy/interpretation/{id}`,
                `post:${Api.path.knowledge}/law/policy/interpretation/batch/{categoryId}`,
                `post:${Api.path.knowledge}/law/policy/interpretation/export`
              ],
              actions: [
                {
                  id: '6c34f590-4082-48aa-9a67-341a755a30d8',
                  name: 'add',
                  title: '新增'
                },
                {
                  id: '7321c487-cbb1-476c-bd41-4112fdbc1843',
                  name: 'edit',
                  title: '编辑'
                },
                {
                  id: '08d0b466-93fe-4eeb-a8da-c5f4ca846869',
                  name: 'del',
                  title: '删除'
                }
              ]
            },
            component: () => import('@/webapp/content/learning/elucidation.vue')
          },
          {
            path: '/bus-problem',
            meta: {
              id: '43a27a26-62e3-4921-afed-919b6d3f4ea0',
              icon: '',
              name: 'bus-problem',
              title: '企业法律百问',
              apis: [
                `get:${Api.path.knowledge}/org/question/page/list`,
                `post:${Api.path.knowledge}/org/question`,
                `delete:${Api.path.knowledge}/org/question/batch`,
                `post:${Api.path.knowledge}/org/question/batch`,
                `get:${Api.path.knowledge}/org/question/{id}`,
                `put:${Api.path.knowledge}/org/question/{id}`,
                `delete:${Api.path.knowledge}/org/question/{id}`,
                `post:${Api.path.knowledge}/org/question/export/{type}`
              ],
              actions: [
                {
                  id: '56489ffa-1dcc-457a-9f31-6c8c62ba54e4',
                  name: 'export',
                  title: '导出',
                  apis: []
                }
              ]
            },
            component: busProblem
          },
          {
            path: '/mp-article',
            meta: {
              id: '8c984bb4-106a-4a23-969f-e5ecbbb220ea',
              icon: '',
              name: 'mp-article',
              title: '订阅号文章',
              apis: [
                `get:${Api.path.knowledge}/mp/document/page/list`,
                `post:${Api.path.knowledge}/mp/document`,
                `get:${Api.path.knowledge}/mp/document/{id}`,
                `put:${Api.path.knowledge}/mp/document/{id}`,
                `delete:${Api.path.knowledge}/mp/document/{id}`
              ]
            },
            component: mpArticle
          }
        ]
      },
      {
        path: '/writ',
        meta: {
          id: 'ccfd0aa7-9989-418a-afed-fdfdcb8637f6',
          icon: 'iconfont icon-wenshumobanguanliicon',
          iconColor: '#36cbdf',
          name: 'writ',
          title: '文件模板管理'
        },
        children: [
          {
            path: '/template',
            meta: {
              id: 'c99aafc2-be98-41b4-9e1b-12a3004615f4',
              icon: '',
              name: 'template',
              title: '合同模板库',
              apis: [
                `get:${Api.path.knowledge}/category/all`,
                `get:${Api.path.knowledge}/law/document/page/list`,
                `put:${Api.path.knowledge}/law/document/cover/{id}`,
                `get:${Api.path.knowledge}/law/document/{id}`
              ],
              actions: [
                {
                  id: '2fbcb2cf-e7da-4380-ab11-c0a6cbc2cd85',
                  name: 'add-category',
                  title: '新增分类',
                  apis: [
                    `post:${Api.path.knowledge}/category`
                  ]
                },
                {
                  id: '36160573-d044-4894-bb56-386c8294ad6c',
                  name: 'edit-category',
                  title: '编辑分类',
                  apis: [
                    `put:${Api.path.knowledge}/category/{id}`
                  ]
                },
                {
                  id: '3720e8e9-1192-4275-a5e7-78f653043257',
                  name: 'del-category',
                  title: '删除分类',
                  apis: [
                    `delete:${Api.path.knowledge}/category/{id}`
                  ]
                },
                {
                  id: 'f0928a7a-b220-46db-9505-8a6403a23b24',
                  name: 'add',
                  title: '新增模版',
                  apis: [
                    `post:${Api.path.knowledge}/law/document`,
                    `put:${Api.path.knowledge}/law/document/cover/{id}`
                  ]
                },
                {
                  id: 'd830bb18-df4e-45b0-b7b1-dc8e92f0e277',
                  name: 'edit',
                  title: '编辑模版',
                  apis: [
                    `put:${Api.path.knowledge}/law/document/{id}`,
                    `put:${Api.path.knowledge}/law/document/cover/{id}`
                  ]
                },
                {
                  id: 'e07660e2-be86-4069-b719-1ed651031f97',
                  name: 'del',
                  title: '删除模版',
                  apis: [
                    `delete:${Api.path.knowledge}/law/document/{id}`
                  ]
                },
                {
                  id: '374a6588-77e2-41d5-b8df-dba60f3c08cb',
                  name: 'import',
                  title: '导入模板',
                  apis: [
                    `post:${Api.path.knowledge}/law/document`
                  ]
                },
                {
                  id: '4bf1a2d1-a1e0-4cbc-9dc6-099c5f6b2840',
                  name: 'download',
                  title: '下载模版',
                  apis: [
                    `get:${Api.path.knowledge}/law/document/{id}`
                  ]
                },
                {
                  id: '35b2458b-79ff-47e6-81d7-0ddb1a4b934b',
                  name: 'batch',
                  title: '批量操作',
                  apis: [
                    `put:${Api.path.knowledge}/law/document/batch`,
                    `delete:${Api.path.knowledge}/law/document/batch`
                  ]
                },
                {
                  id: 'e55d9308-b33a-4da2-87ab-7af58c6159cb',
                  name: 'up-down',
                  title: '上下架',
                  apis: [
                    `put:${Api.path.knowledge}/law/document/status/doc/{id}`,
                    `put:${Api.path.knowledge}/law/document/status/{id}`
                  ]
                }
              ]
            },
            component: writTemplate
          },
          {
            path: '/law-writ',
            meta: {
              id: '80ce2b44-2ff7-48e4-bc59-49c3acca855d',
              icon: '',
              name: 'law-writ',
              title: '法律文书库',
              apis: [
                `post:${Api.path.knowledge}/law/doc`,
                `get:${Api.path.knowledge}/law/doc/page/list`,
                `get:${Api.path.knowledge}/law/doc/{id}`,
                `put:${Api.path.knowledge}/law/doc/{id}`,
                `delete:${Api.path.knowledge}/law/doc/{id}`,
                `put:${Api.path.knowledge}/law/doc/status/doc/{id}`,
                `put:${Api.path.knowledge}/law/doc/batch`
              ],
              actions: [
                {
                  id: '82f61fd3-8ae4-4585-8e10-76bb481807b1',
                  name: 'add-category',
                  title: '新增分类',
                  apis: []
                },
                {
                  id: 'eaa9f081-0b3f-4ffe-975b-50d2239859ae',
                  name: 'edit-category',
                  title: '编辑分类',
                  apis: [

                  ]
                },
                {
                  id: '5d563408-e189-40b8-b3d9-049c5d3c8e7f',
                  name: 'del-category',
                  title: '删除分类',
                  apis: [

                  ]
                },
                {
                  id: '2ec62dea-493e-4469-9294-230e3792df92',
                  name: 'add',
                  title: '新增模版',
                  apis: [
                  ]
                },
                {
                  id: '807f253b-8d99-452c-9b6d-c02ef1d25ca0',
                  name: 'edit',
                  title: '编辑模版',
                  apis: [
                  ]
                },
                {
                  id: '230aa926-d229-483f-b244-39e0aef87a25',
                  name: 'del',
                  title: '删除模版',
                  apis: [
                  ]
                },
                {
                  id: '1d45d2ed-9e1e-484e-a8ca-3a0bc7832b84',
                  name: 'import',
                  title: '导入模板',
                  apis: [
                  ]
                },
                {
                  id: 'b7d4bf10-86a7-457f-8855-433ec58a4593',
                  name: 'download',
                  title: '下载模版',
                  apis: [
                  ]
                },
                {
                  id: 'a1a2987b-0544-405a-9ff0-69caee43d411',
                  name: 'batch',
                  title: '批量操作',
                  apis: [
                  ]
                },
                {
                  id: 'cfa7f534-f948-428d-878d-13808291b7cd',
                  name: 'up-down',
                  title: '上下架',
                  apis: [
                  ]
                }
              ]
            },
            component: lawWrit
          },
          {
            path: '/hr-regime',
            meta: {
              id: '323139db-d3c4-4a85-a621-d579b86a13c3',
              icon: '',
              name: 'hr-regime',
              title: '人事制度库',
              apis: [
                `get:${Api.path.knowledge}/category/all`,
                `post:${Api.path.knowledge}/category`,
                `put:${Api.path.knowledge}/category/{id}`,
                `delete:${Api.path.knowledge}/category/{id}`,
                `get:${Api.path.knowledge}/employee/document/page/list`,
                `post:${Api.path.knowledge}/employee/document`,
                `delete:${Api.path.knowledge}/employee/document/batch`,
                `put:${Api.path.knowledge}/employee/document/batch`,
                `put:${Api.path.knowledge}/employee/document/cover/{id}`,
                `put:${Api.path.knowledge}/employee/document/status/doc/{id}`,
                `put:${Api.path.knowledge}/employee/document/status/{id}`,
                `get:${Api.path.knowledge}/employee/document/{id}`,
                `put:${Api.path.knowledge}/employee/document/{id}`,
                `delete:${Api.path.knowledge}/employee/document/{id}`
              ],
              actions: [
                {
                  id: '5eaebe9c-65a4-4bfc-9082-b2a4fd134bb9',
                  name: 'add',
                  title: '新增模版',
                  apis: [
                  ]
                },
                {
                  id: '2e887f76-cbbf-42fd-94aa-712c4376f54f',
                  name: 'edit',
                  title: '编辑模版',
                  apis: [
                  ]
                },
                {
                  id: 'b4d30f52-efa1-4358-8fc3-0cfb12609c01',
                  name: 'del',
                  title: '删除模版',
                  apis: [
                  ]
                },
                {
                  id: '9c4cd4d2-af80-45e0-9da9-a33f51fa12af',
                  name: 'import',
                  title: '导入模板',
                  apis: [
                  ]
                },
                {
                  id: '2db02ca7-4b8a-4e81-bb62-fe5f5c16c57e',
                  name: 'download',
                  title: '下载模版',
                  apis: [
                  ]
                },
                {
                  id: '03cec77d-0fba-44a5-91a4-1b063aebdc1d',
                  name: 'batch',
                  title: '批量操作',
                  apis: [
                  ]
                },
                {
                  id: '9fd37ceb-5db6-4c90-bd62-ff07b35fccc7',
                  name: 'up-down',
                  title: '上下架',
                  apis: [
                  ]
                }
              ]
            },
            component: () => import('@/webapp/content/hr/regime.vue')
          },
          {
            path: '/hr-contract',
            meta: {
              id: '298221cd-8011-44a7-968d-483f469204af',
              icon: '',
              name: 'hr-contract',
              title: '人事合同库',
              apis: [
                `get:${Api.path.knowledge}/category/all`,
                `post:${Api.path.knowledge}/category`,
                `put:${Api.path.knowledge}/category/{id}`,
                `delete:${Api.path.knowledge}/category/{id}`,
                `get:${Api.path.knowledge}/employee/agreement/page/list`,
                `post:${Api.path.knowledge}/employee/agreement`,
                `delete:${Api.path.knowledge}/employee/agreement/batch`,
                `put:${Api.path.knowledge}/employee/agreement/batch`,
                `put:${Api.path.knowledge}/employee/agreement/cover/{id}`,
                `put:${Api.path.knowledge}/employee/agreement/status/doc/{id}`,
                `put:${Api.path.knowledge}/employee/agreement/status/{id}`,
                `get:${Api.path.knowledge}/employee/agreement/{id}`,
                `put:${Api.path.knowledge}/employee/agreement/{id}`,
                `delete:${Api.path.knowledge}/employee/agreement/{id}`
              ],
              actions: [
                {
                  id: 'ae824a37-7511-4046-803c-cd92bcda0faf',
                  name: 'add-category',
                  title: '新增分类',
                  apis: [
                    `post:${Api.path.knowledge}/category`
                  ]
                },
                {
                  id: '39542f3b-2631-4133-a8c3-7e5384652214',
                  name: 'edit-category',
                  title: '编辑分类',
                  apis: [
                    `put:${Api.path.knowledge}/category/{id}`
                  ]
                },
                {
                  id: 'd35e296c-d43c-4d84-9ba6-8be4a2630205',
                  name: 'del-category',
                  title: '删除分类',
                  apis: [
                    `delete:${Api.path.knowledge}/category/{id}`
                  ]
                },
                {
                  id: '18e4d04b-cccf-410b-8d6e-bdf94b23d0c8',
                  name: 'add',
                  title: '新增模版',
                  apis: [
                  ]
                },
                {
                  id: '539409a5-fe18-4d70-a897-ee970de0ecde',
                  name: 'edit',
                  title: '编辑模版',
                  apis: [
                  ]
                },
                {
                  id: '8617c88a-be23-4779-81af-91d45057ea52',
                  name: 'del',
                  title: '删除模版',
                  apis: [
                  ]
                },
                {
                  id: '76ff070a-c178-4b3b-9e62-64b2ac10fe9a',
                  name: 'import',
                  title: '导入模板',
                  apis: [
                  ]
                },
                {
                  id: 'f05f369e-93c3-4eff-8ea9-0832105733d0',
                  name: 'download',
                  title: '下载模版',
                  apis: [
                  ]
                },
                {
                  id: '8e425c7e-8f0d-4c97-9388-aa109bc982b0',
                  name: 'batch',
                  title: '批量操作',
                  apis: [
                  ]
                },
                {
                  id: '13dffe7b-e9e1-4634-98fa-4d05ff98e980',
                  name: 'up-down',
                  title: '上下架',
                  apis: [
                  ]
                }
              ]
            },
            component: () => import('@/webapp/content/hr/contract.vue')
          },
          {
            path: '/clause',
            meta: {
              id: 'c64bb96e-c25c-4669-8ff0-028d66de06f4',
              icon: '',
              name: 'clause',
              title: '条款管理',
              apis: [
                `get:${Api.path.knowledge}/category/all`,
                `get:${Api.path.knowledge}/law/clause/page/list`,
                `get:${Api.path.knowledge}/law/clause/{id}`
              ],
              actions: [
                {
                  id: 'bb95cb17-4dd8-47d6-87b4-a74813b53473',
                  name: 'add-category',
                  title: '新增分类',
                  apis: [
                    `post:${Api.path.knowledge}/category`
                  ]
                },
                {
                  id: '3ffc79ba-eafd-4da1-999f-428be89f9e05',
                  name: 'edit-category',
                  title: '编辑分类',
                  apis: [
                    `put:${Api.path.knowledge}/category/{id}`
                  ]
                },
                {
                  id: '006eaa1f-bf75-4fc7-8a16-f8c01426b8bb',
                  name: 'del-category',
                  title: '删除分类',
                  apis: [
                    `delete:${Api.path.knowledge}/category/{id}`
                  ]
                },
                {
                  id: 'aa27a4c9-b511-4c87-9a29-544ec9178baa',
                  name: 'add',
                  title: '添加条款',
                  apis: [
                    `post:${Api.path.knowledge}/law/clause`,
                    `post:${Api.path.knowledge}/law/clause/batches/{id}`,
                    `post:${Api.path.knowledge}/law/clause/batch/{id}`
                  ]
                },
                {
                  id: 'fe23acdd-baaa-43fe-a0db-801933abebbd',
                  name: 'edit',
                  title: '编辑条款',
                  apis: [
                    `put:${Api.path.knowledge}/law/clause/{id}`
                  ]
                },
                {
                  id: 'dd2930c0-e04f-4aaf-aa0b-d20a6eeadbad',
                  name: 'delete',
                  title: '删除条款',
                  apis: [
                    `delete:${Api.path.knowledge}/law/clause/{id}`
                  ]
                }
              ]
            },
            component: writClause
          },
          {
            path: '/risk',
            meta: {
              id: 'b5755d75-c8ea-4919-848e-1013d94e6ae6',
              icon: '',
              name: 'risk',
              title: '风险点管理',
              apis: [
                `get:${Api.path.knowledge}/law/risk/page/list`,
                `get:${Api.path.knowledge}/law/risk/{id}`
              ],
              actions: [
                {
                  id: '71ed66bb-b2a7-4d28-85e7-aecc7f8b5d9c',
                  name: 'add',
                  title: '添加风险点',
                  apis: [
                    `post:${Api.path.knowledge}/law/risk`,
                    `post:${Api.path.knowledge}/law/risk/batch`
                  ]
                },
                {
                  id: 'c99fd732-3fc8-4ed7-839a-a4da34ce8682',
                  name: 'edit',
                  title: '编辑风险点',
                  apis: [
                    `put:${Api.path.knowledge}/law/risk/{id}`
                  ]
                },
                {
                  id: '2ff72e4c-1fe7-4e07-9e14-3ff64b3c943a',
                  name: 'del',
                  title: '删除风险点',
                  apis: [
                    `delete:${Api.path.knowledge}/law/risk/{id}`
                  ]
                }

              ]
            },
            component: writRisk
          },
          {
            path: '/sensitive',
            meta: {
              id: '80b8f4ba-0fcc-4017-9f5d-77c866291f38',
              icon: '',
              name: 'sensitive',
              title: '敏感词管理',
              apis: [
                `get:${Api.path.knowledge}/law/sensitive/word/page/list`,
                `get:${Api.path.knowledge}/law/sensitive/word/{id}`
              ],
              actions: [
                {
                  id: 'be988ba9-bfcc-41fe-b3b9-396ead5d206c',
                  name: 'add',
                  title: '添加敏感词',
                  apis: [
                    `post:${Api.path.knowledge}/law/sensitive/word`
                  ]
                },
                {
                  id: 'baf00537-609c-4042-8ce2-b969e40ba62e',
                  name: 'edit',
                  title: '编辑敏感词',
                  apis: [
                    `put:${Api.path.knowledge}/law/sensitive/word/{id}`
                  ]
                },
                {
                  id: '43bf1979-1663-4f57-be2b-d7d5296d5252',
                  name: 'del',
                  title: '删除敏感词',
                  apis: [
                    `delete:${Api.path.knowledge}/law/sensitive/word/{id}`
                  ]
                }
              ]
            },
            component: writSensitive
          },
          {
            path: '/keyword',
            meta: {
              id: '3168987b-c7c2-4fe4-bcde-b7afeb4b8afd',
              icon: '',
              name: 'keyword',
              title: '关键词管理',
              apis: [
                `get:${Api.path.knowledge}/law/key/word/page/list`,
                `get:${Api.path.knowledge}/law/key/word/{id}`
              ],
              actions: [
                {
                  id: '560d954e-e90d-4ccd-bb4a-a31bcf05745a',
                  name: 'add',
                  title: '添加关键词',
                  apis: [
                    `post:${Api.path.knowledge}/law/key/word`
                  ]
                },
                {
                  id: 'be37ae3d-d4a8-4b2e-99c9-89406281e114',
                  name: 'edit',
                  title: '编辑关键词',
                  apis: [
                    `put:${Api.path.knowledge}/law/key/word/{id}`
                  ]
                },
                {
                  id: '5b3f06db-acc0-4063-a55f-9c7dfeb638ff',
                  name: 'del',
                  title: '删除关键词',
                  apis: [
                    `delete:${Api.path.knowledge}/law/key/word/{id}`
                  ]
                }
              ]
            },
            component: writKeyword
          }

        ]
      },
      {
        path: '/classroom',
        meta: {
          id: 'd0a9e509-1cad-4171-af50-41ed473157f6',
          icon: 'iconfont icon-wenshumobanguanliicon',
          iconColor: '#36cbdf',
          name: 'classroom',
          title: '法图课堂'
        },
        children: [
          {
            path: '/course',
            meta: {
              id: '64b5692e-4fa7-4e7f-bdd2-52b3812233db',
              icon: null,
              iconColor: null,
              name: 'course',
              title: '课程列表',
              apis: [
                `get:${Api.path.knowledge}/course/page/list`,
                `get:${Api.path.knowledge}/course/{id}`,
                `put:${Api.path.knowledge}/course/{id}`,
                `post:${Api.path.knowledge}/course`,
                `delete:${Api.path.knowledge}/course/{id}`,
                `put:${Api.path.knowledge}/course/status/{id}`,
                `put:${Api.path.knowledge}/course/code/{id}`,
                `put:${Api.path.knowledge}/course/code/chapter/{id}`
              ],
              actions: [
                { id: '37ebce28-539c-4e11-9f64-ced0da0b45e5', name: 'add_category', title: '新增分类' },
                { id: '46b8afcd-9bd5-4e86-995e-f474a48fda3c', name: 'edit_category', title: '编辑分类' },
                { id: '7a4eca7b-1ae7-4de6-b394-190c950f3697', name: 'del_category', title: '删除分类' },
                { id: 'a1093e1b-2519-4da3-ba6c-6c0632bd69ab', name: 'add', title: '新增课程' },
                { id: '53bb2047-5af1-47af-9a31-9ed8b0c50029', name: 'edit', title: '编辑课程' },
                { id: '68bca13f-7304-4c0b-9827-35b53bab752d', name: 'del', title: '删除课程' },
                { id: '0000171a-b744-44a4-9423-677fedb7fb28', name: 'updown', title: '上架/下架' }
              ]
            },
            component: classroomCourse
          }
        ]
      }
    ]
  }
]
